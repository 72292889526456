<style scoped>
.sermon-iframe:-webkit-full-screen {
  min-width: 0 !important;
  max-width: none !important;
  width: 100% !important;
  min-height: 0 !important;
  max-height: none !important;
}
</style>

<template>
  <div class="container pt-4">
    <iframe
      id="#sermonnetframe"
      v-resize="{ log: false }"
      src="https://whiteoak.sermon.net/main"
      scrolling="no"
      frameborder="0"
      style="max-width: 100%; width: 100%; min-height: 800px; overflow: hidden"
      allowTransparency="true"
      allowfullscreen
      class="sermon-iframe"
    ></iframe>
    <div v-if="oldestStreamingDate" class="mb-4">
      For recordings since {{oldestStreamingDate}}, use the search box above. For Nov 2022-{{newestArchiveDate}} recordings, <router-link to="/sermons">Click here</router-link>.
      <p>&nbsp;</p>
    </div>
  </div>
</template>

<script setup>
import iframeResize from 'iframe-resizer/js/iframeResizer';
import { ref } from 'vue';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebaseConfig'
import dayjs from 'dayjs';
const oldestStreamingDate = ref(null);
const newestArchiveDate = ref(null);

getDoc(doc(db, "siteinfo", "audio")).then(s=>{
  const streamDate = s.data().oldest_streaming_date.toDate();
  const archiveDate = s.data().newest_archive_date.toDate();
  oldestStreamingDate.value = dayjs(streamDate).format('MMM D, YYYY');
  newestArchiveDate.value = dayjs(archiveDate).format('MMM YYYY');
})

const vResize = {
  mounted: (el, { value = {} }) => {
    el.addEventListener('load', () => iframeResize(value, el));
  },
  unmounted: (el) => {
    el.iFrameResizer.removeListeners();
  },
};
</script>