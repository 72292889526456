<template>
  <div class="container pt-4">
    <div class="pb-6">
      <div class="d-blockmb-3">
        <label for="mobile-search">Filter by Speaker:</label>
        <input type="text" id="mobile-search" class="form-control" v-model="search">
      </div>

      <div class="">
        <div v-for="sermon in sermonList" v-bind:key="sermon.id" class="card mb-3">
          <div class="card-body" v-if="sermon.html" v-html="sermon.html"></div>
          <div class="card-body" v-if="!sermon.html">
            <h5 class="card-title">{{ sermon.title }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ formatSermonDate(sermon.date.toDate()) }}</h6>
            <p class="card-text">
              <strong v-if="sermon.speaker">Speaker:</strong> {{ sermon.speaker }}<br>
              <strong v-if="sermon.passage">Passage:</strong> {{ sermon.passage }}
            </p>
            <div v-if="sermon.mainspeakerurl" class="mb-2">
              <a :href="sermon.mainspeakerurl" target="_blank" class="btn btn-sm btn-outline-primary mr-2">Main speaker</a>
            </div>
            <div v-if="sermon.fullserviceurl" class="mb-2">
              <a :href="sermon.fullserviceurl" target="_blank" class="btn btn-sm btn-outline-primary mr-2">Watch/Listen</a>
            </div>
            <div v-if="sermon.slidesurl || sermon.slidesUrl">
              <a :href="sermon.slidesurl ?? sermon.slidesUrl" target="_blank" class="btn btn-sm btn-outline-secondary">Slides</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { collection, query, orderBy, where } from "firebase/firestore";
import { useFirestore, useCollection } from 'vuefire'
import { formatSermonDate } from "../utils/formatDate.js";

const search = ref('');
const db = useFirestore();
const col = collection(db, "audio");

const firestoreQuery = computed(() => {
  if (search.value) {
    return query(col,
      orderBy("speaker"),
      orderBy("date", "desc"),
      where("speaker", ">=", search.value),
      where("speaker", "<", search.value + '\uf8ff')
    );
  } else {
    return query(col, orderBy("date", "desc"));
  }
});

const sermonList = useCollection(firestoreQuery);
</script>

<script>
export default { name: "SermonsComponent" };
</script>

<style scoped>
.sermon-table-headings th {
  vertical-align: top;
}

/* Mobile card styles */
@media (max-width: 767.98px) {
  .card-title {
    font-size: 1.1rem;
  }
  
  .card-subtitle {
    font-size: 0.9rem;
  }
}
</style>